<template>
  <div class>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">进度统计</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="initData">刷新</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-table
      :data="data"
      border
      class="egrid erp-table__list erp-view__table custom-table"
      @cell-click="getCell"
      :row-class-name="tableRowClassName"
      style="margin-top: 15px"
    >
      <el-table-column
        :label="item"
        v-for="(item, index) in labelDatas"
        :key="index"
        :prop="'row' + index"
        align="center"
        :width="widths[index]"
      ></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { httpGet } from '@/api';
export default {
  components: {},
  props: {},
  data() {
    return {
      labelDatas: [
        '',
        '订单签约',
        '客户付保证金',
        '付外商预付款',
        '发货',
        '副本',
        '正本',
        '客户付尾款',
        '付外商尾款',
        '清关',
        '送货',
        '提货',
        '开票',
      ],
      data: [],
      widths: [
        '94px',
        '84px',
        '82px',
        '82px',
        '70px',
        '70px',
        '70px',
        '82px',
        '82px',
        '70px',
        '70px',
        '70px',
        '72px',
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    getCell(row, column) {
      const index = row.index;
      const rowName = column.label;
      // let count=row.
      const obj = {
        label: '',
        value: null,
        name: null,
        status: index,
      };
      switch (rowName) {
        case '订单签约':
          obj.label = 'isSigned';
          obj.value = 1;
          obj.name = '订单签约';
          break;
        case '客户付保证金':
          obj.label = 'isPaidDeposit';
          obj.value = 2;
          obj.name = '客户付保证金';
          break;
        case '付外商预付款':
          obj.label = 'isPaidForeignDeposit';
          obj.value = 3;
          obj.name = '付外商预付款';
          break;
        case '发货':
          obj.label = 'isShipping';
          obj.value = 4;
          obj.name = '发货';
          break;
        case '副本':
          obj.label = 'isCopyFile';
          obj.value = 5;
          obj.name = '副本';
          break;
        case '正本':
          obj.label = 'isOriginalFile';
          obj.value = 6;
          obj.name = '正本';
          break;
        case '客户付尾款':
          obj.label = 'isPaidFinal';
          obj.value = 7;
          obj.name = '客户付尾款';
          break;
        case '付外商尾款':
          obj.label = 'isPaidForeignFinal';
          obj.value = 8;
          obj.name = '付外商尾款';
          break;
        case '清关':
          obj.label = 'isClearance';
          obj.value = 9;
          obj.name = '清关';
          break;
        case '送货':
          obj.label = 'isDelivery';
          obj.value = 10;
          obj.name = '送货';
          break;
        case '提货':
          obj.label = 'isPickUp';
          obj.value = 11;
          obj.name = '提货';
          break;
        case '开票':
          obj.label = 'isInvoicing';
          obj.value = 12;
          obj.name = '开票';
          break;
        default:
          break;
      }
      if (rowName !== '' && row[column.property] > 0) {
        this.$emit('change', obj);
      }
    },
    async initData() {
      const res = await httpGet('/malicrm/order/orderStepStatistics');
      if (res.code === 200) {
        const data = res.result;
        const arr = [
          {
            row0: '当前进度',
            row1: data.currentSignedCount, // 订单签约
            row2: data.currentDepositCount, // 客户付保证金
            row3: data.currentForeignDepositCount, // 付外商预付款
            row4: data.currentShippingCount, // 发货
            row5: data.currentCopyCount, // 副本
            row6: data.currentOriginalCount, // 正本
            row7: data.currentFinalCount, // 客户付尾款
            row8: data.currentForeignFinalCount, // 付外商尾款
            row9: data.currentClearanceCount, // 清关
            row10: data.currentDeliveryCount, // 送货
            row11: data.currentPickUpCount, // 提货
            row12: data.currentInvoiceCount, // 订单签约
          },
          {
            row0: '出现异常',
            row1: data.abnormalSignedCount, // 订单签约
            row2: data.abnormalDepositCount, // 客户付保证金
            row3: data.abnormalForeignDepositCount, // 付外商预付款
            row4: data.abnormalShippingCount, // 发货
            row5: data.abnormalCopyCount, // 副本
            row6: data.abnormalOriginalCount, // 正本
            row7: data.abnormalFinalCount, // 客户付尾款
            row8: data.abnormalForeignFinalCount, // 付外商尾款
            row9: data.abnormalClearanceCount, // 清关
            row10: data.abnormalDeliveryCount, // 送货
            row11: data.abnormalPickUpCount, // 提货
            row12: data.abnormalInvoiceCount, // 订单签约
          },
          {
            row0: '特别关注',
            row1: null, // 订单签约
            row2: data.depositFocusCount, // 客户付保证金
            row3: data.foreignDepositFocusCount, // 付外商预付款
            row4: data.shippingFocusCount, // 发货
            row5: null, // 副本
            row6: null, // 正本
            row7: data.finalFocusCount, // 客户付尾款
            row8: data.foreignFinalFocusCount, // 付外商尾款
            row9: null, // 清关
            row10: null, // 送货
            row11: data.pickUpFocusCount, // 提货
            row12: null, // 订单签约
          },
        ];
        this.data = arr;
      } else {
        this.errorTip(res.message);
      }
    },
    back() {
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>

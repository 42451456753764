<template>
  <div v-loading="loading" element-loading-text="正在导入，请勿进行其他操作">
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">{{ title }}</div>
        <div class="erp-form-bar">
          <template v-if="showType === 1">
            <el-button size="small" type="primary" class="erp-primary__btn" @click="exportStart">开始导入</el-button>
            <el-button size="small" type="primary" class="erp-primary__btn" @click="templateManage">模板管理</el-button>
            <div class="erp-default__btn" @click="cancel">返回</div>
          </template>
          <template v-else>
            <el-button size="small" type="primary" class="erp-primary__btn" @click="confirmExport">确认导入</el-button>
            <div class="erp-default__btn" @click="back">返回</div>
          </template>
        </div>
      </div>
      <template v-if="showType === 1">
        <div style="padding: 0 16px">
          <div class="erp-form__title f16 import-top req no-leftborder" style="">选择导入文件</div>

          <div class="upload-box">
            <div class="header-title">
              <el-select v-model="template" placeholder="请选择导入模板" clearable size="medium" @clear="clearTemplate" @change="change">
                <el-option v-for="item in templateList" :key="item.id" :label="item.template" :value="item.id"></el-option>
              </el-select>
              <div class="erp-form__title f16 import-top no-leftborder header-tip">
                <span>导入文件必须符合模板格式，请下载</span><span class="template-link" @click="downLoadTmp">默认模板</span>
              </div>
            </div>
            <SingleFileContent
              :uploadUrl="customUrl"
              :isImport="true"
              :titleImg="''"
              :fromType="1"
              :fileType="fileType"
              :queryNum="0"
              :max="1"
              :typeImg="1"
              tips=" "
              :fileList="fileUrlsArray"
              @getFiles="getFiles"
              ref="importUpload"
              :autoUpload="false"
              :uploadParams="uploadParams"
            >
            </SingleFileContent>
            <div class="upload-tips">允许类型：xls、xlsx&nbsp;&nbsp;&nbsp;文件大小上限：30M&nbsp;&nbsp;&nbsp;只支持上传1个文件&nbsp;&nbsp;&nbsp;</div>
          </div>
        </div>
        <!-- 应收项目 -->
        <div v-if="errorShow">
          <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
            <div class="erp-form__title f16 no-leftborder">错误日志</div>
          </div>
          <div>
            <el-table :data="errorData" border class="egrid erp-table__list erp-view__table">
              <el-table-column label="错误行数" align="center" show-overflow-tooltip width="190" class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.cellRowDesc) }}
                </template>
              </el-table-column>
              <el-table-column label="错误原因" align="center" show-overflow-tooltip class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.description) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <template v-else>
        <el-table
          :data="formData.dtoList"
          border
          class="egrid erp-table__list erp-view__table table-loading"
          id="scrollTables"
          row-key="arapAuditSeq"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>
          <template v-for="(item, index) in formData.columnList" :key="item">
            <el-table-column :label="item" class-name="no-pd req" align="center">
              <template v-slot="scope">
                {{ filterName(scope.row[formData.columnEnList[index]]) }}
              </template>
            </el-table-column>
          </template>
        </el-table>
      </template>
    </div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { computed, reactive, ref, nextTick } from 'vue';
import { errorTip, exportFile, showFiles, successTip } from '@/utils/publicMethods';
import useVisible from '@/hooks/useVisible';
import { httpPost } from '@/api';
export default {
  components: {
    SingleFileContent,
  },
  props: {},
  emits: ['cancel', 'manage', 'exportSuccess'],
  setup(props, ctx) {
    const templateList = ref([]);
    const step = ref(1);
    const files = ref([]);
    const selectionList = ref([]);
    const fileUrlsArray = ref([]);
    const loading = ref(false);
    const errorShow = ref(false);
    const isAjaxLoading = ref(false);
    const template = ref(null);
    const importUpload = ref('');
    const tableFileUpLoad = ref('');
    const showType = ref(1);
    const uploadParams = reactive({
      id: null,
    });
    const formData = reactive({
      dtoList: [],
      columnList: [],
      columnEnList: [],
    });
    const title = computed(() => {
      return showType.value === 1 ? '导入数据' : '数据导入表';
    });
    const customUrl = ref(process.env.VUE_APP_API_ROOT + '/malicrm/order/orderImportData');
    const fileType = ref('excel');
    // 展示批量上传文件展示
    const { visible: UpLoadVisable, showVisible: showUpLoadVisable, closeVisible: closeUpLoadVisable } = useVisible();
    const errorData = ref([]); // 错误信息列表
    const errorQueryForm = reactive({
      logId: null,
      pageNum: 1,
      pageSize: 10,
    });
    // 开始导入校验条件
    const validForm = () => {
      let valid = true;
      if (!template.value) {
        let valid = false;
        errorTip('请选择导入模板');
        return valid;
      }
      return valid;
    };
    // 开始导入点击
    // const exportStart = () => {
    //   console.log('url', customUrl);
    //   // todo
    //   console.log('开始导入');

    //   // todo
    //   importUpload.value.submit();
    //   loading.value = true;
    // };
    // 清空下拉模板
    const clearTemplate = () => {
      console.log(!template.value == true);
    };
    const initTemplateData = async () => {
      let res = await httpPost('/malicrm/order/queryTemplateMessageByTemplateName', {});
      if (res.code === 200) {
        res.result.forEach((v) => {
          let params = {
            template: v.templateName,
            id: v.id,
          };
          templateList.value.push(params);
        });
      }
    };
    // 模板管理
    const templateManage = () => {
      console.log('模板管理');
      ctx.emit('manage', 2);
    };
    // 返回
    const back = () => {
      showType.value = 1;
    };
    // 取消
    const cancel = () => {
      ctx.emit('cancel', false, 1);
    };
    // 模板下载
    const downLoadTmp = async () => {
      exportFile({
        type: 'POST',
        url: '/malicrm/order/downLoadTemplate',
        data: {},
      });
    };
    // 选中列表
    const selectionChange = (row) => {
      selectionList.value = row;
    };
    // 确认导入
    const confirmExport = async () => {
      // todo
      if (selectionList.value.length > 0) {
        let res = await httpPost('/malicrm/order/confirmImportOrderData', selectionList.value);
        if (res.code === 200) {
          successTip('导入成功');
          ctx.emit('exportSuccess', true);
        } else {
          errorTip(res.message);
        }
      } else {
        errorTip('请选择要导入的数据');
      }
    };

    const getLogData = async () => {
      let res = await httpPost('/malicrm/excel/queryExcelImportLineLogDetailList', errorQueryForm);
      if (res.code === 200) {
        console.log(2222);
        errorData.value = res.result.list;
      } else {
        errorTip(res.message);
      }
    };

    // 点击开始导入，获取返回数据
    const getFiles = (res) => {
      loading.value = false;
      if (res.code === 200) {
        if (res.result.hasError === true) {
          errorTip('导入失败');
          errorQueryForm.logId = res.result.importLogId;
          getLogData();
          errorShow.value = false;
          setTimeout(() => {
            errorShow.value = true;
          }, 50);
        } else {
          successTip('导入成功');
          let { dataVos, columnList, columnEnList } = res.result;
          formData.dtoList = dataVos;
          formData.columnList = columnList;
          formData.columnEnList = columnEnList;
          step.value = 2;
          showType.value = 2;
        }
      } else {
        errorTip(res.message);
      }
      nextTick(() => {
        if (importUpload.value) {
          importUpload.value.fileCount = 0;
        }
        fileUrlsArray.value = [];
      });
    };
    // 开始导入
    const exportStart = () => {
      uploadParams.id = template.value;
      const len = importUpload.value.fileCount;
      if (validForm()) {
        if (len > 0) {
          importUpload.value.submitUpload();
        } else {
          errorTip('请上传文件');
        }
      }
    };
    const submit = async () => {
      if (!isAjaxLoading.value) {
        isAjaxLoading.value = true;
        let res = await httpPost('/malicrm/orderArap/saveApAuditFromExcel', { dtoList: formData.dtoList });
        if (res.code === 200) {
          successTip('提交成功');
          ctx.emit('cancel', true);
        } else {
          errorTip(res.message);
        }
      }
    };
    const getRowFileUrls = ({ data, index }) => {
      // 单行文件编辑修改
      formData.dtoList[index].attachmentDtoList = data;
      // 行文件标记
    };
    const getIndexs = (targetArrays, currentArrays, name) => {
      // 获取对应下标值
      const indexs = [];
      currentArrays.forEach((v) => {
        indexs.push(targetArrays.findIndex((item) => item[name] === v[name]));
      });
      return indexs;
    };
    const getFinalPayFile = ({ data }) => {
      const indexs = getIndexs(formData.dtoList, selectionList.value, 'arapAuditSeq');
      indexs.forEach((index) => {
        formData.dtoList[index].attachmentDtoList = data;
      });
    };

    const showUpLoad = () => {
      if (selectionList.value.length > 0) {
        UpLoadVisable.value = false;
        setTimeout(() => {
          showUpLoadVisable();
        }, 10);
        setTimeout(() => {
          tableFileUpLoad.value.uploadVisable = true;
        }, 100);
      } else {
        errorTip('请选择关联数据');
      }
    };
    const getFileSuccess = (list) => {
      console.log('list', list);
      console.log('上传成功');
    };
    // 获取模板信息
    initTemplateData();
    return {
      title,
      getFileSuccess,
      importUpload,
      templateList,
      downLoadTmp,
      showFiles,
      loading,
      errorShow,
      template,
      errorData,
      isAjaxLoading,
      errorQueryForm,
      UpLoadVisable,
      showUpLoadVisable,
      closeUpLoadVisable,
      files,
      step,
      customUrl,
      fileType,
      selectionList,
      fileUrlsArray,
      formData,
      validForm,
      cancel,
      getLogData,
      getRowFileUrls,
      getIndexs,
      getFinalPayFile,
      showUpLoad,
      templateManage,
      initTemplateData,
      showType,
      back,
      confirmExport,
      uploadParams,
      selectionChange,
      clearTemplate,
      exportStart,
      getFiles,
      submit,
    };
  },
  methods: {
    // getFiles(res) {
    //   // 获取导入数据，构建数据列
    //   this.loading = false;
    //   if (res.code === 200) {
    //     if (res.result.hasError === true) {
    //       this.errorTip('导入失败');
    //       this.errorQueryForm.logId = res.result.importLogId;
    //       this.getLogData();
    //       this.errorShow = false;
    //       setTimeout(() => {
    //         this.errorShow = true;
    //       }, 50);
    //     } else {
    //       this.successTip('导入成功');
    //       this.formData.dtoList = res.result.dataVos;
    //       this.formData.columnList = res.result.columnList;
    //       this.formData.columnEnList = res.result.columnEnList;
    //       this.step = 2;
    //       this.showType = 2;
    //     }
    //   } else {
    //     this.errorTip(res.message);
    //   }
    //   this.$nextTick(() => {
    //     if (this.$refs.importUpload) {
    //       this.$refs.importUpload.fileCount = 0; // 必须置空，否则直接上传不会触发;
    //     }
    //     this.fileUrlsArray = [];
    //   });
    // },
    // exportStart() {
    //   // 校验下一步,触发手动上传
    //   console.log(this.template, 'dsaasdasddssad');
    //   this.uploadParams.id = this.template;
    //   const len = this.$refs.importUpload.fileCount;
    //   if (this.validForm()) {
    //     if (len > 0) {
    //       this.$refs.importUpload.submitUpload();
    //     } else {
    //       this.errorTip('请上传文件');
    //     }
    //   }
    // },
    // change() {
    //   console.log(this.template);
    // },
    // submit() {
    //   if (!this.isAjaxLoading) {
    //     this.isAjaxLoading = true;
    //     this.ajax({
    //       type: 'POST',
    //       url: '/malicrm/orderArap/saveApAuditFromExcel',
    //       data: {
    //         dtoList: this.formData.dtoList,
    //       },
    //       success: (res) => {
    //         this.isAjaxLoading = false;
    //         if (res.code === 200) {
    //           this.successTip('提交成功');
    //           this.$emit('cancel', true);
    //         } else {
    //           this.errorTip(res.message);
    //         }
    //       },
    //       error: (res) => {
    //         console.log(res);
    //         this.isAjaxLoading = false;
    //       },
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.upload-tips {
  display: flex;
  align-items: center;
  white-space: pre;
  font-size: 14px;
  color: #9a9a9a;
  position: absolute;
  top: 192px;
  .record__link {
    color: #2878ff;
  }
  .under {
    text-decoration: underline;
  }
}
.import-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
.upload-box {
  position: relative;
}
.erp-form__title.req::after {
  content: '*';
  color: #f04838;
  margin-left: 4px;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.header-tip {
  margin: 0 0 0 12px;
  color: #999999;
  font-size: 14px;
}
.template-link {
  color: #2878ff;
  cursor: pointer;
  text-decoration: underline;
}
.upload-tips {
  top: 242px;
}
</style>

<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">订单进度</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="loading-warp">
      <div class="view-step__warp">
        <ul>
          <!-- 步骤1 -->
          <li class="load-step" :class="{ active: !isNull(data.scheduleOneVo.signDate) }">
            <div class="step-inner">
              <div class="step-item" :class="{ null: isNull(data.scheduleOneVo.signDate) }">订单签约时间：{{ filterString(data.scheduleOneVo.signDate) }}</div>
              <div class="step-item" :class="{ null: isNull(data.scheduleOneVo.orderCreatedTime) }">
                订单创建时间：{{ filterString(data.scheduleOneVo.orderCreatedTime) }}
              </div>
            </div>
          </li>
          <!-- 步骤2 如果抬头类型是“客户指定”，“客户付保证金日期”和“客户付尾款日期”这两个节点不显示。-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleTwoVo.orderDepositTime) }" v-if="!data.isCustomerConsignee">
            <div class="step-inner">
              <div class="step-item" :class="{ null: isNull(data.scheduleTwoVo.orderDepositTime) }">
                客户付保证金日期：{{ filterString(data.scheduleTwoVo.orderDepositTime) }}
              </div>
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleTwoVo.expectedOrderDepositTime),
                  overdue: data.scheduleTwoVo.isExpired,
                }"
              >
                预计付保证金日期：{{ filterString(data.scheduleTwoVo.expectedOrderDepositTime) }}
              </div>

              <desc-more
                v-if="data.scheduleTwoVo.isAbnormal"
                :id="2"
                :desc="data.scheduleTwoVo.depositAbnormalDesc"
                :operator="data.scheduleTwoVo.depositOperator"
                :updateTime="data.scheduleTwoVo.depositUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>

          <!-- 步骤3 edit -->
          <li
            class="load-step"
            :class="{
              active: !isNull(data.scheduleThreeVo.foreignOrderDepositTime),
            }"
          >
            <div class="step-inner">
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleThreeVo.foreignOrderDepositTime),
                }"
              >
                抬头付外商预付款日期：{{ filterString(data.scheduleThreeVo.foreignOrderDepositTime) }}
              </div>
              <div class="step-item" v-if="data.isCustomerGoodsPayment" :class="{ null: isNull(data.scheduleThreeVo.foreignRatioTime) }">
                贸易商付工厂预付款日期：{{ filterString(data.scheduleThreeVo.foreignRatioTime) }}
              </div>
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleThreeVo.expectedForeignOrderDepositTime),
                  overdue: data.scheduleThreeVo.isExpired,
                }"
              >
                预计付外商预付款日期：{{ filterString(data.scheduleThreeVo.expectedForeignOrderDepositTime) }}
              </div>
              <desc-more
                v-if="data.scheduleThreeVo.isAbnormal"
                :id="3"
                :desc="data.scheduleThreeVo.foreignDepositAbnormalDesc"
                :operator="data.scheduleThreeVo.foreignDepositOperator"
                :updateTime="data.scheduleThreeVo.foreignDepositUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>
          <!--<li class="load-step" :class="{active:!isNull(data.scheduleThreeVo.foreignRatioTime)}"-->
          <!--v-if="!(!data.isNeedJr && data.isCustomerConsignee)">-->
          <!--<div class="step-inner">-->
          <!--<div class="step-item"  :class="{null:isNull(data.scheduleThreeVo.foreignRatioTime)}">-->
          <!--贸易商付工厂预付款日期：{{data.scheduleThreeVo.foreignRatioTime |filterString() }}-->
          <!--</div>-->
          <!--</div>-->
          <!--</li>-->

          <!-- 步骤4 -->
          <li class="load-step" :class="{ active: !isNull(data.scheduleFourVo.shippingStartDate) }">
            <div class="step-inner flex-step">
              <div class="inner-left">
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.scheduleFourVo.shippingStartDate),
                  }"
                >
                  启运日：{{ filterString(data.scheduleFourVo.shippingStartDate) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.scheduleFourVo.expectedOrderDeliveryTime),
                    overdue: data.scheduleFourVo.isExpired,
                  }"
                >
                  预计提醒发货日期：{{ filterString(data.scheduleFourVo.expectedOrderDeliveryTime) }}
                </div>
                <div class="step-item" :class="{ null: isNull(data.scheduleFourVo.ladingBillNo) }">
                  提单号：
                  <el-tooltip
                    :content="data.scheduleFourVo.ladingBillNo"
                    effect="dark"
                    class="custom-tooltip"
                    :disabled="!data.scheduleFourVo.ladingBillNo || (data.scheduleFourVo.ladingBillNo && data.scheduleFourVo.ladingBillNo.length < 16)"
                  >
                    <div class="inline-span">
                      {{ filterString(data.scheduleFourVo.ladingBillNo) }}
                    </div>
                  </el-tooltip>
                </div>
                <div class="step-item" :class="{ null: isNull(data.scheduleFourVo.shippingInfo) }">
                  船名航次：{{ filterString(data.scheduleFourVo.shippingInfo) }}
                </div>

                <desc-more
                  v-if="data.scheduleFourVo.isAbnormal"
                  :id="4"
                  :desc="data.scheduleFourVo.shippingAbnormalDesc"
                  :operator="data.scheduleFourVo.shippingOperator"
                  :updateTime="data.scheduleFourVo.shippingUpdateTime"
                  @change="showEditDesc"
                  style="overflow: inherit"
                >
                </desc-more>
              </div>
              <div class="inner-right">
                <div class="step-item" :class="{ null: isNull(data.scheduleFourVo.shippingEndDate) }">
                  到港日：{{ filterString(data.scheduleFourVo.shippingEndDate) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.scheduleFourVo.expectedOrderShipTime),
                  }"
                >
                  预计船期：{{ filterString(data.scheduleFourVo.expectedOrderShipTime) }}
                </div>
                <div class="step-item" :class="{ null: isNull(data.scheduleFourVo.containerNo) }">
                  柜号：{{ filterString(data.scheduleFourVo.containerNo) }}
                </div>
              </div>
            </div>
          </li>
          <!-- 步骤5-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleFiveVo.urgeCopyStatusDesc) }">
            <div class="step-inner" :class="{ descPadding: moreStatus1 }">
              <div class="flex-step">
                <div class="inner-left">
                  <div
                    class="step-item"
                    :class="{
                      null: isNull(data.scheduleFiveVo.urgeCopyStatusDesc),
                    }"
                  >
                    副本状态：{{ filterString(data.scheduleFiveVo.urgeCopyStatusDesc) }}
                  </div>
                  <div
                    class="step-item"
                    :class="{
                      null: isNull(data.scheduleFiveVo.expectedUrgeCopyTime),
                      overdue: data.scheduleFiveVo.isExpired,
                    }"
                  >
                    预计催收副本日期：{{ filterString(data.scheduleFiveVo.expectedUrgeCopyTime) }}
                  </div>

                  <div class="step-item" :class="{ null: isNull(data.scheduleFiveVo.orderCount) }">
                    订单总件数：{{ filterString(data.scheduleFiveVo.orderCount) }}
                  </div>

                  <div
                    class="step-item"
                    style="margin-bottom: 3px"
                    :class="{
                      null: isNull(data.scheduleFiveVo.isNeedInsurance),
                    }"
                  >
                    是否买保险：{{ filterString(data.scheduleFiveVo.isNeedInsurance) }}
                  </div>
                </div>
                <div class="inner-right">
                  <div class="step-item"></div>
                  <div class="step-item"></div>
                  <div
                    class="step-item"
                    :class="{
                      null: isNull(data.scheduleFiveVo.orderActualWeight),
                    }"
                  >
                    订单总净重：{{ filterString(data.scheduleFiveVo.orderActualWeight) }}
                  </div>
                </div>
              </div>
              <div class="step-item" :class="{ null: isNull(data.scheduleFiveVo.remark) }">
                <div class="desc__inner" :class="{ autoHeight: moreStatus1 }">
                  <div class="desc" id="copyDesc" :class="{ null: isNull(data.scheduleFiveVo.remark) }">
                    <span>副本描述：{{ filterString(data.scheduleFiveVo.remark) }}</span>
                  </div>
                </div>
                <div v-if="showMore1" class="show-more" @click="changeMore1">
                  <i :class="moreStatus1 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                  {{ moreStatus1 ? '隐藏更多' : '显示更多' }}
                </div>
              </div>
              <desc-more
                v-if="data.scheduleFiveVo.isAbnormal"
                :id="5"
                :desc="data.scheduleFiveVo.copyAbnormalDesc"
                :operator="data.scheduleFiveVo.copyOperator"
                :updateTime="data.scheduleFiveVo.copyUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>
          <!-- 步骤6 -->
          <li class="load-step" :class="{ active: !isNull(data.scheduleSixVo.originalStatusDesc) }">
            <div class="step-inner" :class="{ descPadding: moreStatus1 }">
              <div class="step-item" :class="{ null: isNull(data.scheduleSixVo.originalStatusDesc) }">
                正本状态：{{ filterString(data.scheduleSixVo.originalStatusDesc) }}
              </div>
              <div class="step-item" :class="{ null: isNull(data.scheduleSixVo.elecInfoStatus) }">
                电子信息：
                <el-tooltip
                  :content="data.scheduleSixVo.elecInfoStatus"
                  effect="dark"
                  class="custom-tooltip"
                  :disabled="!data.scheduleSixVo.elecInfoStatus || (data.scheduleSixVo.elecInfoStatus && data.scheduleSixVo.elecInfoStatus.length < 20)"
                >
                  <div class="inline-span">
                    {{ filterString(data.scheduleSixVo.elecInfoStatus) }}
                  </div>
                </el-tooltip>
              </div>
              <div class="step-item" :class="{ null: isNull(data.scheduleSixVo.trackingNumber) }" style="margin-bottom: 3px; display: flex">
                正本快递单号：
                <el-tooltip
                  :content="data.scheduleSixVo.trackingNumber"
                  effect="dark"
                  class="custom-tooltip"
                  :disabled="!data.scheduleSixVo.trackingNumber || (data.scheduleSixVo.trackingNumber && data.scheduleSixVo.trackingNumber.length < 20)"
                >
                  <div class="inline-span max-width">
                    {{ filterString(data.scheduleSixVo.trackingNumber) }}
                  </div>
                </el-tooltip>
                <!-- 有图片或者有进度信息 -->
                <span
                  @click="showImage(data.scheduleSixVo)"
                  :class="(data.scheduleSixVo.fileUrl && data.scheduleSixVo.fileUrl !== '') || data.scheduleSixVo.deliveryMessages ? '' : 'null'"
                  >快递进度</span
                >
              </div>

              <div class="step-item" :class="{ null: isNull(data.scheduleSixVo.remark) }">
                <div class="desc__inner" :class="{ autoHeight: moreStatus2 }">
                  <div class="desc" id="copyDesc2">
                    <span>正本描述：{{ filterString(data.scheduleSixVo.remark) }}</span>
                  </div>
                </div>
                <div v-if="showMore2" class="show-more" @click="changeMore2">
                  <i :class="moreStatus2 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                  {{ moreStatus2 ? '隐藏更多' : '显示更多' }}
                </div>
              </div>
              <desc-more
                v-if="data.scheduleSixVo.isAbnormal"
                :id="6"
                :desc="data.scheduleSixVo.originalAbnormalDesc"
                :operator="data.scheduleSixVo.originalOperator"
                :updateTime="data.scheduleSixVo.originalUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>
          <!-- 步骤7 如果订单垫资，“客户付尾款日期”这一进度节点不显示 如果抬头类型是“客户指定”，“客户付保证金日期”和“客户付尾款日期”这两个节点不显示。-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleSevenVo.orderFinalTime) }" v-if="!data.isNeedJr && !data.isCustomerConsignee">
            <div class="step-inner">
              <div class="step-item" :class="{ null: isNull(data.scheduleSevenVo.orderFinalTime) }">
                客户付尾款日期：{{ filterString(data.scheduleSevenVo.orderFinalTime) }}
              </div>
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleSevenVo.expectedOrderFinalTime),
                  overdue: data.scheduleSevenVo.isExpired,
                }"
              >
                预计客户付尾款日期：{{ filterString(data.scheduleSevenVo.expectedOrderFinalTime) }}
              </div>
              <desc-more
                v-if="data.scheduleSevenVo.isAbnormal"
                :id="7"
                :desc="data.scheduleSevenVo.finalAbnormalDesc"
                :operator="data.scheduleSevenVo.finalOperator"
                :updateTime="data.scheduleSevenVo.finalUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>

          <!-- 步骤8edit -->
          <li
            class="load-step"
            :class="{
              active: !isNull(data.scheduleEightVo.foreignOrderFinalTime) || !isNull(data.scheduleEightVo.isSettledDesc),
            }"
          >
            <div class="step-inner">
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleEightVo.foreignOrderFinalTime),
                }"
              >
                抬头付国外尾款日期：{{ filterString(data.scheduleEightVo.foreignOrderFinalTime) }}
              </div>
              <div class="step-item" v-if="data.isCustomerGoodsPayment" :class="{ null: isNull(data.scheduleEightVo.foreignTailTime) }">
                贸易商付工厂尾款日期：{{ filterString(data.scheduleEightVo.foreignTailTime) }}
              </div>
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleEightVo.expectedForeignOrderFinalTime),
                  overdue: data.scheduleEightVo.isExpired,
                }"
              >
                预计付国外尾款日期：{{ filterString(data.scheduleEightVo.expectedForeignOrderFinalTime) }}
              </div>
              <desc-more
                v-if="data.scheduleEightVo.isAbnormal"
                :id="8"
                :desc="data.scheduleEightVo.foreignFinalAbnormalDesc"
                :operator="data.scheduleEightVo.foreignFinalOperator"
                :updateTime="data.scheduleEightVo.foreignFinalUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>
          <!--<li class="load-step" :class="{active:!isNull(data.scheduleEightVo.foreignTailTime)}"-->
          <!--v-if="!(!data.isNeedJr && data.isCustomerConsignee) && data.isCustomerGoodsPayment">-->
          <!--<div class="step-inner">-->
          <!--<div class="step-item"  :class="{null:isNull(data.scheduleEightVo.foreignTailTime)}">-->
          <!--贸易商付工厂尾款日期：{{data.scheduleEightVo.foreignTailTime |filterString()}}-->
          <!--</div>-->
          <!--</div>-->
          <!--</li>-->

          <!-- 步骤9-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleNineVo.cleStatus) }">
            <div class="step-inner">
              <div class="step-item" :class="{ null: isNull(data.scheduleNineVo.cleStatus) }">清关状态：{{ filterString(data.scheduleNineVo.cleStatus) }}</div>
              <div
                class="step-item"
                :class="{
                  null: isNull(data.scheduleNineVo.expectedClearDate),
                  overdue: data.scheduleNineVo.isExpired,
                }"
              >
                预计完成清关日期：{{ filterString(data.scheduleNineVo.expectedClearDate) }}
              </div>
              <div class="step-item" :class="{ null: isNull(data.scheduleNineVo.trackingNumber) }" style="display: flex">
                报关资料快递单号：
                <el-tooltip
                  :content="data.scheduleNineVo.trackingNumber"
                  effect="dark"
                  class="custom-tooltip"
                  :disabled="!data.scheduleNineVo.trackingNumber || (data.scheduleNineVo.trackingNumber && data.scheduleNineVo.trackingNumber.length < 20)"
                >
                  <div class="inline-span max-width">
                    {{ filterString(data.scheduleNineVo.trackingNumber) }}
                  </div>
                </el-tooltip>
                <span @click="showImage(data.scheduleNineVo.fileUrl)" :class="data.scheduleNineVo.fileUrl && data.scheduleNineVo.fileUrl !== '' ? '' : 'null'"
                  >快递进度</span
                >
              </div>
              <desc-more
                v-if="data.scheduleNineVo.isAbnormal"
                :id="9"
                :desc="data.scheduleNineVo.clearanceAbnormalDesc"
                :operator="data.scheduleNineVo.clearanceOperator"
                :updateTime="data.scheduleNineVo.clearanceUpdateTime"
                @change="showEditDesc"
              >
              </desc-more>
            </div>
          </li>
          <!-- 步骤10-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleTenVo.deliverStatus) }">
            <div class="step-inner flex-step">
              <div class="inner-left">
                <div class="step-item" :class="{ null: isNull(data.scheduleTenVo.deliverStatus) }">
                  送货状态：{{ filterString(data.scheduleTenVo.deliverStatus) }}
                </div>
                <div class="step-item" :class="{ null: isNull(data.scheduleTenVo.deliverDate) }">
                  送货日期：{{ filterString(data.scheduleTenVo.deliverDate) }}
                </div>
                <desc-more
                  v-if="data.scheduleTenVo.isAbnormal"
                  :id="10"
                  :desc="data.scheduleTenVo.deliveryAbnormalDesc"
                  :operator="data.scheduleTenVo.deliveryOperator"
                  :updateTime="data.scheduleTenVo.deliveryUpdateTime"
                  @change="showEditDesc"
                  style="overflow: inherit"
                >
                </desc-more>
              </div>
              <div class="inner-right">
                <div class="step-item"></div>
              </div>
            </div>
          </li>

          <li class="load-step" :class="{ active: !isNull(data.schedulePickStatusVo.pickStatus) }" v-if="data.schedulePickStatusVo">
            <div class="step-inner flex-step">
              <div class="inner-left">
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.schedulePickStatusVo.pickStatusDesc),
                  }"
                >
                  提货状态：{{ filterString(data.schedulePickStatusVo.pickStatusDesc) }}
                </div>
                <div class="step-item" :class="{ null: isNull(data.schedulePickStatusVo.leftCount) }">
                  库存剩余件数：{{ filterString(data.schedulePickStatusVo.leftCountDesc) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.schedulePickStatusVo.settleStatusDesc),
                  }"
                >
                  客户是否结清：{{ filterString(data.schedulePickStatusVo.settleStatusDesc) }}
                </div>
                <desc-more
                  v-if="data.schedulePickStatusVo.isAbnormal"
                  :id="11"
                  :desc="data.schedulePickStatusVo.pickUpAbnormalDesc"
                  :operator="data.schedulePickStatusVo.pickUpOperator"
                  :updateTime="data.schedulePickStatusVo.pickUpUpdateTime"
                  @change="showEditDesc"
                  style="overflow: inherit"
                >
                </desc-more>
              </div>
              <div class="inner-right">
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.schedulePickStatusVo.pickStatusDesc),
                  }"
                >
                  当前冷库：{{ filterString(data.scheduleTenVo.storageName) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.schedulePickStatusVo.leftRedeemCount),
                  }"
                >
                  待赎单件数：{{ filterString(data.schedulePickStatusVo.leftRedeemCountDesc) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.schedulePickStatusVo.businessContractEndDate),
                    overdue: data.schedulePickStatusVo.isExpired,
                  }"
                >
                  销售合同到期日：{{ filterString(data.schedulePickStatusVo.businessContractEndDate) }}
                </div>
              </div>
            </div>
          </li>

          <!-- 步骤12-->
          <li class="load-step" :class="{ active: !isNull(data.scheduleElevenVo.invoiceAmount) }">
            <div class="step-inner flex-step">
              <div class="inner-left">
                <div class="step-item" :class="{ null: isNull(data.scheduleElevenVo.invoiceAmount) }">
                  已开票金额：{{ filterNum(data.scheduleElevenVo.invoiceAmount) }}
                </div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.scheduleElevenVo.unInvoiceAmount),
                  }"
                >
                  未开票金额：{{ filterNum(data.scheduleElevenVo.unInvoiceAmount) }}
                </div>
              </div>
              <div class="inner-right">
                <div class="step-item"></div>
                <div
                  class="step-item"
                  :class="{
                    null: isNull(data.scheduleElevenVo.invoiceStatusDesc),
                  }"
                >
                  开票状态：{{ filterString(data.scheduleElevenVo.invoiceStatusDesc) }}
                </div>
              </div>
            </div>
          </li>
          <!-- 步骤12-->
          <li class="load-step" :class="{ active: !isNull(data.finishTime) }">
            <div class="step-inner">
              <div class="step-item" :class="{ null: isNull(data.finishTime) }">订单完结时间：{{ filterString(data.finishTime) }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="view-remark__warp">
        <div class="remark-title">
          <i class="el-icon-warning"></i>
          订单备注
        </div>
        <div class="remark-body">{{ filterString(data.remark) }}</div>
      </div>
    </div>

    <el-dialog title="查看图片" v-model="imgVisable" width="680px" custom-class="p-30" @close="imgVisable = false" :append-to-body="true">
      <div class="min-img-warp">
        <img :src="url" alt />
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button size="small" @click="imgVisable = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="编辑异常说明"
      v-model="descVisable"
      width="600px"
      custom-class="p-16 p-30"
      @close="descVisable = false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div style="padding: 0 16px 16px">
        <div style="margin-bottom: 11px">异常说明（限200字）</div>
        <div>
          <el-input v-model="descContent" placeholder="" type="textarea" :rows="5" :maxlength="200"></el-input>
        </div>
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button size="small" type="primary" @click="saveDesc">保存</el-button>
          <el-button size="small" @click="descVisable = false">返回</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 快递信息 -->
    <el-dialog title="查看快递信息" v-model="deliveryMessagesVisible" width="612px" custom-class="p-30 express" @close="deliveryMessagesVisible = false">
      <div>
        <express-progress :expressData="deliveryMessages"></express-progress>
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button size="small" @click="deliveryMessagesVisible = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { httpGet, httpPost } from '@/api';
import descMore from './descMore';
import expressProgress from './expressProgress.vue';
let that;
export default {
  components: {
    descMore,
    expressProgress,
  },
  props: {
    editData: {
      type: [Object],
    },
    orderId: {
      type: [String],
    },
  },
  data() {
    return {
      currentStep: null,
      descContent: null,
      descVisable: false,
      imgVisable: false,
      deliveryMessagesVisible: false, //快递信息展示
      deliveryMessages: [], //快递信息展示
      url: null, // 快递单号地址
      showMore1: false,
      moreStatus1: false,
      showMore2: false,
      moreStatus2: false,
      data: {
        finishTime: null,
        orderId: 0,
        remark: null,
        scheduleEightVo: {
          expectedForeignOrderFinalTime: null,
          foreignOrderFinalTime: null,
          isExpired: false,
        },
        scheduleElevenVo: {
          invoiceAmount: 0,
          unInvoiceAmount: 0,
        },
        scheduleFiveVo: {
          expectedUrgeCopyTime: null,
          isExpired: false,
          isNeedInsurance: false,
          orderActualWeight: null,
          orderCount: null,
          remark: null,
          urgeCopyStatus: null,
          urgeCopyStatusDesc: null,
        },
        scheduleFourVo: {
          containerNo: null,
          expectedOrderDeliveryTime: null,
          expectedOrderShipTime: null,
          isExpired: false,
          ladingBillNo: null,
          shippingEndDate: null,
          shippingInfo: null,
          shippingStartDate: null,
        },
        scheduleNineVo: {
          cleStatus: null,
          fileUrl: null,
          trackingNumber: null,
          expectedClearDate: null,
          isAbnormal: null,
        },
        scheduleOneVo: {
          orderCreatedTime: null,
          signDate: null,
        },
        scheduleSevenVo: {
          expectedOrderFinalTime: null,
          isExpired: false,
          orderFinalTime: null,
        },
        scheduleSixVo: {
          elecInfoStatus: null,
          originalStatus: null,
          remark: null,
          fileUrl: null,
          trackingNumber: null,
          originalStatusDesc: null,
        },
        scheduleTenVo: {
          businessContractEndDate: null,
          deliverDate: null,
          deliverStatus: null,
          leftCount: 0,
          leftRedeemCount: 0,
          isExpired: false,
        },
        scheduleThreeVo: {
          expectedForeignOrderDepositTime: null,
          foreignOrderDepositTime: null,
          isExpired: false,
        },
        scheduleTwoVo: {
          expectedOrderDepositTime: null,
          isExpired: false,
          orderDepositTime: null,
        },
      },
    };
  },
  created() {
    this.initData(this.orderId);
    that = this;
  },
  methods: {
    async saveDesc() {
      // 保存异常说明
      const res = await httpPost('malicrm/order/editOrderAbnormal', {
        abnormalDesc: this.descContent,
        orderId: this.orderId,
        step: this.currentStep,
      });
      if (res.code === 200) {
        this.initData(this.orderId);
        this.descVisable = false;
      }
    },
    showEditDesc({ data, id }) {
      this.descContent = null;
      this.currentStep = id;
      this.descContent = data;
      this.descVisable = true;
    },
    showHeight() {
      const copyDesc = document.querySelector('#copyDesc>span');
      const offsetHeight = copyDesc.offsetHeight;
      this.showMore1 = offsetHeight > 48;
      const copyDesc2 = document.querySelector('#copyDesc2>span');
      const offsetHeight2 = copyDesc2.offsetHeight;
      this.showMore2 = offsetHeight2 > 48;
    },
    showImage(data) {
      let { deliveryMessages, fileUrl } = data;
      if (fileUrl && fileUrl !== '') {
        this.imgVisable = true;
        this.url = fileUrl;
      } else {
        // 如果有deliveryMessages 快递信息则渲染快递信息
        if (deliveryMessages && deliveryMessages !== '') {
          // 取出节点数据
          this.deliveryMessages = JSON.parse(deliveryMessages).lastResult.data;
          this.deliveryMessagesVisible = true;
        }
      }
    },
    isNull(val) {
      return val === null || val === undefined || val === '' || val === 0 || val === false || val === '0';
    },
    async initData(orderId) {
      const res = await httpGet(`malicrm/order/queryOrderScheduleById/${orderId}`);
      if (res.code === 200) {
        this.data = res.result;
        this.$nextTick(() => {
          this.showHeight();
        });
      }
    },
    changeMore1() {
      this.moreStatus1 = !this.moreStatus1;
    },
    changeMore2() {
      this.moreStatus2 = !this.moreStatus2;
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
    filterString(val) {
      if (val === null || val === '' || val === undefined) {
        return '无';
      } else {
        return val;
      }
    },
    filterNum(val) {
      if (val === null || val === '') {
        return 0;
      } else {
        const str = that._thousandBitSeparator(val);
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-warp {
  padding: 0 40px;
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}
.view-remark__warp {
  margin-left: 40px;
  width: 396px;
  box-shadow: 0px 0px 10px 0px rgba(35, 100, 209, 0.12);
  border-radius: 4px;
  .remark-title {
    display: flex;
    align-items: center;
    padding: 17px;
    font-size: 14px;
    color: #303133;
    border-bottom: 1px dashed #e5e9f2;
    i {
      font-size: 19px;
      color: #c7c8cd;
      margin-right: 9px;
    }
  }
  .remark-body {
    padding: 20px 17px;
    font-size: 14px;
    color: #303133;
    line-height: 1.5;
  }
}

.load-step {
  display: flex;
  position: relative;
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #d9e1ec;
    margin-right: 20px;
    z-index: 2;
    position: relative;
  }
  &::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 0;
    width: 2px;
    z-index: 1;
    height: 100%;
    background: #d9e1ec;
    display: block;
  }
  &.active {
    &::before {
      background: #2878ff;
    }
  }
  &:last-child {
    &::after {
      width: 0;
    }
  }
}
.step-inner {
  padding: 21px 27px 20px 17px;
  box-shadow: 0px 0px 10px 0px rgba(35, 100, 209, 0.12);
  border-radius: 4px;
  width: 450px;
  margin-bottom: 20px;
  &.descPadding {
    padding: 21px 27px 18px 17px;
  }
}
.step-item {
  font-size: 14px;
  color: #303133;
  margin-bottom: 8px;
  min-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  > span {
    color: #2878ff;
    font-size: 14px;
    margin-left: 30px;
    cursor: pointer;
    &.null {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  &.overdue {
    color: #f04737;
  }
  &.null {
    color: #c0c4cc;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .desc {
    line-height: 1.75;
    text-overflow: ellipsis; /*有些示例里需要定义该属性，实际可省略*/
    display: -webkit-box;
    -webkit-line-clamp: 2; /*规定超过两行的部分截断*/
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
  }
  .desc__inner {
    max-height: 48px;
    overflow: hidden;
  }
  .autoHeight {
    height: auto;
    max-height: none;
    .desc {
      -webkit-line-clamp: 99999; /*规定超过两行的部分截断*/
    }
  }
}
.step-item {
  .exp {
    color: #303133;
    .null {
      opacity: 0.3;
    }
    .exp-txt {
      width: 340px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .edit-exp {
    margin-left: 30px;
    opacity: 1;
    cursor: pointer;
    color: #2878ff;
  }
  &.null {
    opacity: 1;
    cursor: not-allowed;
    color: #c0c4cc;
  }
  &.overdue {
    color: #f04737;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.flex-step {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .inner-left {
    margin-right: 28px;
    max-width: 230px;
  }
}
.show-more {
  margin-top: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  justify-content: center;
  color: #2878ff;
  opacity: 0.3;
  i {
    font-size: 20px;
    position: relative;
    top: -1px;
    left: -4px;
  }
  &:hover {
    opacity: 1;
  }
}
.flex-inner {
  display: flex;
  align-items: center;
  .step-item {
    margin-bottom: 0;
    &:first-child {
      width: 233px;
    }
  }
}
.inline-span {
  display: inline;
  cursor: pointer;
  &.max-width {
    max-width: 218px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
  }
}
</style>


import { defineComponent, getCurrentInstance, nextTick, reactive, ref, toRefs, watch } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import ErpFilter from '@/components/ErpList/ErpFilter.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, erpConfirm, errorMessage, exportFile, loadPage, errorTip, successTip } from '@/utils/publicMethods';
import router from '@/router';
import ErpDrawer from '@/components/ErpDrawer/ErpDrawer.vue';
import LoadingView from './components/LoadingView.vue';
import OrderStatistics from './components/orderStatistics.vue';
import SingleFileContent from '@/components/FileContent/FileContent.vue';

import { RouteLocationRaw } from 'node_modules/vue-router/dist/vue-router';
import { CrmFilterOptions, ErpTableOptions } from '@/types/type';
import useTimer from '@/hooks/useTimer';

// 导入数据组件
import exportData from './components/exportData.vue';
import importData from './components/importData.vue';
import TemplateManage from './components/templateManage.vue';
export default defineComponent({
  name: 'OrderList',
  components: { ErpList, ErpFilter, ErpDrawer, LoadingView, OrderStatistics, exportData, TemplateManage, importData, SingleFileContent },
  props: {},
  setup() {
    const Instance = getCurrentInstance() as any;
    const { startTimer, timeStatus } = useTimer();
    const { columnList, exportAllOptions, PurchaseOrderOptions, tableRef } = useListData(Instance);
    const { visible, showVisible } = useVisible();
    const { visible: statisticsVisible, showVisible: showStatisticsVisible } = useVisible();
    const { visible: dataVisible, showVisible: showDataVisible, closeVisible: closeDataVisible } = useVisible();
    const { visible: exportVisible, showVisible: showExportVisible, closeVisible: closeExportVisible } = useVisible();
    const { visible: batchImportVisible, showVisible: showBatchImportVisible, closeVisible: closeBatchImportVisible } = useVisible();
    const { visible: dialogVisible, showVisible: showDialogVisible, closeVisible: closeDialogVisible } = useVisible();
    const fileUrlsArray = ref([]);
    const importUpload = ref<any>();
    const errorData = ref([
      {
        isEdit: false,
      },
    ]);
    const template = ref(null);

    const customUrl = ref(process.env.VUE_APP_API_ROOT + '/malicrm/order/batchImportOrder');
    const fileType = ref('excel');
    const rowId = ref('');
    const customParams: any = ref();
    const showType = ref(1);
    type ExportRaw = {
      type: 'purchase-order' | 'all-order';
      formatFilterOption: ErpTableOptions[];
      popperVisible: boolean;
      title: string;
    };
    // 导出参数
    const exportData: ExportRaw = reactive({
      type: 'purchase-order',
      formatFilterOption: [],
      popperVisible: false,
      title: '采购订单导出',
    });
    const exportFilterRef = ref();
    const setExport = (type: string) => {
      exportData.popperVisible = false;
      if (type === 'purchase-order') {
        console.log(PurchaseOrderOptions);
        exportData.type = 'purchase-order';
        exportData.title = '采购订单导出';
        exportData.formatFilterOption = JSON.parse(JSON.stringify(PurchaseOrderOptions));
        showExportVisible();
        nextTick(() => {
          exportFilterRef.value.initFilterData(false);
        });
      } else {
        exportData.type = 'all-order';
        exportData.title = '请筛选导出项目';
        exportData.formatFilterOption = JSON.parse(JSON.stringify(exportAllOptions));
        showExportVisible();
        nextTick(() => {
          exportFilterRef.value.initFilterData(false);
        });
      }
    };
    // 获取导出参数
    const getExportParams = (data) => {
      closeExportVisible();
      if (exportData.type === 'all-order') {
        if (timeStatus.value === true) {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportQhdcOrderTemplate',
            data: data,
          });
        } else {
          errorMessage('导出的点击间隔请勿小于30秒');
        }
      } else {
        if (timeStatus.value === true) {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportCgDataList',
            data: data,
          });
        } else {
          errorMessage('导出的点击间隔请勿小于30秒');
        }
      }
    };

    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 查看详情
    const rowClick = (row: { orderId: any; version: any }, column: { label: any }) => {
      const { label } = column;
      if (label !== '订单进度') {
        loadPage('OrderDetail', {
          orderId: row.orderId,
          version: row.version,
          name: 'OrderList',
        });
      }
    };
    // 点击模板管理
    const templateManage = () => {
      showType.value = 2;
    };
    // 导入成功
    const exportSuccess = (status: boolean) => {
      dataVisible.value = false;
      refreshTable(status);
    };
    // 导入成功
    const exportSuccessImoprt = () => {
      closeBatchImportVisible();
      refreshTable();
    };
    // 关闭导入抽屉
    const closeDrawer = (status: boolean) => {
      closeBatchImportVisible();
      if (status) {
        refreshTable();
      }
    };
    // 点击新增模板
    const addTemp = () => {
      showType.value = 3;
    };

    // 返回
    const exportCancel = (status: boolean, type: number) => {
      if (type === 1) {
        closeDataVisible();
      } else if (type === 2) {
        showType.value = 1;
      } else {
        showType.value = 2;
      }
      // 刷新列表
      if (status) {
        refreshTable(status);
      }
    };
    // 展示订单进度
    const showLoading = (row: { orderId: string }) => {
      rowId.value = row.orderId;
      visible.value = true;
    };
    // 关闭弹层
    const closeVisible = (status: any = false) => {
      visible.value = false;
      statisticsVisible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    // 获取进度统计查询参数
    const getStatic = (data: { status: number; value: any }) => {
      const params: any = {};
      let filterParams: Pick<CrmFilterOptions, 'value' | 'prop'>[] = [];
      if (data.status === 1) {
        params.status = [1];
        params.byteStepAbnormal = [data.value];
        filterParams = [
          {
            prop: 'status',
            value: [1],
          },
          {
            prop: 'byteStepAbnormal',
            value: [data.value],
          },
        ];
      }
      if (data.status === 0) {
        params.status = [1];
        params.byteCurrentSteps = [data.value];
        filterParams = [
          {
            prop: 'status',
            value: [1],
          },
          {
            prop: 'byteCurrentSteps',
            value: [data.value],
          },
        ];
      }
      if (data.status === 2) {
        params.status = [1];
        params.focus = data.value;
        filterParams = [
          {
            prop: 'status',
            value: [1],
          },
        ];
      }
      tableRef.value.paginationParams.pageNum = 1;
      // 手动修改筛选内容，手动触发
      tableRef.value.queryForm = Object.assign({}, params);
      const tableFilter = tableRef.value.erpFilterRef;

      tableFilter.addFilterItem(filterParams);
      statisticsVisible.value = false;
      // 设置完所有参数以后再执行查询
      tableRef.value.getTableDatas();
    };

    const getLoadPage = (url: RouteLocationRaw) => {
      router.push(url);
    };
    // 筛选导出
    const exportFileByHead = () => {
      // 30秒内只能点击一次方法，
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportQhdcOrderTableHeadTemplate',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    watch(dataVisible, (newVal) => {
      if (newVal) {
        showType.value = 1;
      }
    });
    const showBatchExportVisible = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          exportFile({
            type: 'POST',
            url: '/malicrm/order/batchExportOrder',
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    const fullscreenLoading = ref<boolean>(false);
    // 点击开始导入，获取返回数据
    const getFiles = (res) => {
      fullscreenLoading.value = false;
      if (res.code === 200) {
        if (res.result?.length) {
          errorTip('导入文件有错误信息，请检查');
          errorData.value = res.result;
          errorData.value.forEach((item) => {
            item.isEdit = false;
          });
          closeBatchImportVisible();
          setTimeout(() => {
            showBatchImportVisible();
          }, 50);
          closeDialogVisible();
        } else {
          successTip('导入成功');
        }
      } else {
        errorTip(res.message);
      }
      nextTick(() => {
        if (importUpload.value) {
          importUpload.value.fileCount = 0;
        }
        fileUrlsArray.value = [];
      });
    };
    // 开始导入
    const exportStart = () => {
      const len = importUpload.value.fileCount;
      if (validForm()) {
        if (len > 0) {
          importUpload.value.submitUpload();
        } else {
          errorTip('请上传文件');
        }
      }
    };
    const confirmImport = () => {
      const len = importUpload.value.fileCount;
      if (len > 0) {
        fullscreenLoading.value = true;
        importUpload.value.submitUpload();
      } else {
        errorTip('请先上传文件');
      }
    };

    const cancelImport = () => {
      closeDialogVisible();
      fileUrlsArray.value = [];
    };
    // 开始导入校验条件
    const validForm = () => {
      let valid = true;
      if (!template.value) {
        let valid = false;
        errorTip('请选择导入模板');
        return valid;
      }
      return valid;
    };
    return {
      fullscreenLoading,
      cancelImport,
      template,
      errorData,
      exportStart,
      confirmImport,
      getFiles,
      importUpload,
      fileUrlsArray,
      fileType,
      customUrl,
      dialogVisible,
      showDialogVisible,
      closeDialogVisible,
      closeDrawer,
      showBatchExportVisible,
      batchImportVisible,
      showBatchImportVisible,
      closeBatchImportVisible,
      showType,
      exportFilterRef,
      getExportParams,
      ...toRefs(exportData),
      setExport,
      exportVisible,
      closeExportVisible,
      showExportVisible,
      exportFileByHead,
      customParams,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      closeVisible,
      rowClick,
      getLoadPage,
      statisticsVisible,
      showStatisticsVisible,
      getStatic,
      showLoading,
      rowId,
      dataVisible,
      showDataVisible,
      closeDataVisible,
      templateManage,
      exportCancel,
      addTemp,
      exportSuccess,
      exportSuccessImoprt,
    };
  },
});


import { httpGet, httpPost } from '@/api';
import useVisible from '@/hooks/useVisible';
import { store } from '@/store';
import { erpConfirm, errorTip, exportFile, successTip } from '@/utils/publicMethods';
import { defineComponent, reactive, ref, watch } from 'vue';
export default defineComponent({
  emits: ['cancel', 'addTemp'],
  setup(props, ctx) {
    const formData = reactive({
      templateName: null,
      businessType: null,
      isDefaultTemplate: null,
    });
    const formRef = ref('');
    const dataList = ref([]);

    //
    const disabled = ref(0);
    // 勾选中的列表
    const selectList = ref([]);
    const tempalteRef = ref('');
    const exportTempalteRef = ref('');
    let { visible, showVisible, closeVisible } = useVisible();
    let downloadId = ref('');
    // 业务对象列表
    const businessTypeList = ref([]);
    const isDefaultTemplateList = ref([]);
    const addDataList = ref([]);
    // 表单规则
    const rules = reactive({
      templateName: {
        required: true,
        message: '请输入模板名称',
        trigger: 'change',
      },
      businessType: {
        required: true,
        message: '请选择业务对象',
        trigger: 'change',
      },
      isDefaultTemplate: {
        required: true,
        message: '请选择是否默认模板',
        trigger: 'change',
      },
    });
    const initTableData = () => {
      let params1 = [
        { value: 1, label: '销售合同号' },
        { value: 2, label: '柜号' },
        { value: 3, label: '供应商合同号' },
      ];
      let params2 = [
        {
          value: true,
          label: '是',
        },
        {
          value: false,
          label: '否',
        },
      ];
      params1.forEach((v) => {
        (businessTypeList.value as any).push(v);
      });
      params2.forEach((v) => {
        (isDefaultTemplateList.value as any).push(v);
      });
      addDataList.value = (store.state.dict?.options as any).import_template_column_cn;
      addDataList.value.forEach((v) => {
        (v as any).isSure = true;
      });
      console.log(addDataList.value);
    };

    // 点击序列行
    const checkClick = (row, index) => {
      dataList.value.forEach((v, i) => {
        if (i === index) {
          (v as any).isCheck = !(v as any).isCheck;
        } else {
          (v as any).isCheck = false;
        }
      });
      downloadId.value = row.id;
    };
    // 取消
    const cancel = () => {
      ctx.emit('cancel', false, 2);
    };
    // 默认勾选
    const toggleSelection = (rows) => {
      (exportTempalteRef.value as any).clearSelection();
      if (rows) {
        rows.forEach((row) => {
          (exportTempalteRef.value as any).toggleRowSelection(row);
        });
      } else {
        (exportTempalteRef.value as any).clearSelection();
      }
    };
    // 默认不可点击
    const selectable = (row, index) => {
      if (index === disabled.value - 1) {
        return false;
      } else {
        return true;
      }
    };
    // 下拉框业务对象选择事件
    const bussinessTypeChange = (event) => {
      // event 返回的是businessTypeList里的value值 1,2,3
      console.log('event', event);
      disabled.value = event;
      // 默认选中
      let index = event - 1;
      toggleSelection([addDataList.value[index]]);
    };
    const selectionAddChange = (row) => {
      selectList.value = row;
    };
    // 初始化数据
    const initData = async () => {
      let res = await httpGet('/malicrm/order/orderImportTemplateList');
      if (res.code === 200) {
        dataList.value = res.result;
      } else {
        errorTip(res.message);
      }
    };
    // 保存模板
    const save = () => {
      if (selectList.value.length > 1) {
        (formRef.value as any).validate(async (valid) => {
          if (valid) {
            // 新增模板
            let { businessType, isDefaultTemplate, templateName } = formData;
            let templateDetailDtos = [];
            selectList.value.forEach((v) => {
              let obj = {
                columnName: null,
                isSure: false,
              };
              obj.columnName = (v as any).label;
              obj.isSure = (v as any).isSure;
              (templateDetailDtos as any).push(obj);
            });
            let params = {
              businessType,
              isDefaultTemplate,
              templateName,
              templateDetailDtos,
            };
            let res = await httpPost('/malicrm/order/insertTemplate', params);
            if (res.code === 200) {
              successTip('新增模板成功');
              closeVisible();
              initData();
            } else {
              errorTip(res.message);
            }
          } else {
            errorTip('请填写所有带星号的必填项！');
          }
        });
      } else {
        errorTip('请至少勾选两个字段');
      }
    };
    // 模板下载
    const templateDownload = async () => {
      if (downloadId.value && downloadId.value !== '') {
        exportFile({
          type: 'POST',
          url: '/malicrm/order/downLoadTemplate',
          data: { id: downloadId.value },
        });
      } else {
        errorTip('请勾选下载模板的数据');
      }
    };
    // 新增模板
    const addTemplate = () => {
      // ctx.emit('addTemp');
      showVisible();
    };
    // 列表中的删除
    const remove = (row) => {
      erpConfirm('是否删除此模板？').then(async () => {
        let param = {
          id: row.id,
          version: row.version,
        };
        let res = await httpPost('/malicrm/order/deletedOrderTemplate', param);
        if (res.code === 200) {
          successTip('删除成功');
          initData();
        } else {
          errorTip(res.message);
        }
      });
    };

    // 初始化数据
    initData();
    initTableData();
    watch(
      () => visible.value,
      (newVal) => {
        if (!newVal) {
          // 清楚填选的内容,以及表格中的状态
          formData.templateName = null;
          formData.businessType = null;
          formData.isDefaultTemplate = null;
          disabled.value = 0;
          initData();
          initTableData();
          toggleSelection([]);
        }
      }
    );
    return {
      disabled,
      formData,
      cancel,
      templateDownload,
      addTemplate,
      selectable,
      dataList,
      rules,
      initData,
      remove,
      tempalteRef,
      exportTempalteRef,
      downloadId,
      visible,
      showVisible,
      closeVisible,
      businessTypeList,
      addDataList,
      isDefaultTemplateList,
      save,
      formRef,
      selectionAddChange,
      selectList,
      checkClick,
      bussinessTypeChange,
    };
  },
});

<template>
  <div class="step-item exp">
    <div class="desc__inner" :class="{ autoHeight: moreStatus }" style="width: 405px; display: flex; align-items: flex-end">
      <div class="desc" :id="'abnormalDesc' + id">
        <span :class="{ gray: !desc }">异常说明：{{ filterString(desc) }} </span>
        <span v-if="desc">。</span>
        <span v-if="desc">
          {{ operator }}
          {{ updateTime }}
        </span>
        <span style="color: #2878ff; margin-left: 10px; cursor: pointer" v-if="moreStatus" @click="showEditDesc(data, id)">编辑</span>
      </div>
      <span style="color: #2878ff; margin-left: 10px; position: relative; top: 0px; cursor: pointer" v-if="!moreStatus" @click="showEditDesc(data, id)"
        >编辑</span
      >
    </div>
    <div v-if="showMore" class="show-more" @click="changeMore">
      <i :class="moreStatus ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
      {{ moreStatus ? '隐藏更多' : '显示更多' }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['data', 'id', 'desc', 'operator', 'updateTime'],
  data() {
    return {
      showMore: false,
      moreStatus: false,
    };
  },
  mounted() {
    this.showHeight();
  },
  watch: {
    desc: {
      handler() {
        this.$nextTick(() => {
          this.moreStatus = false;
          this.showHeight();
        });
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    showHeight() {
      const copyDesc = document.querySelector(`#abnormalDesc${this.id}>span`);
      const offsetHeight = copyDesc.offsetHeight;
      this.showMore = offsetHeight > 48;
      if (this.showMore) {
        this.moreStatus = false;
      } else {
        this.moreStatus = true;
      }
    },
    showEditDesc(data, id) {
      this.$emit('change', {
        data: this.desc,
        id: id,
      });
    },
    changeMore() {
      this.moreStatus = !this.moreStatus;
    },
    filterString(value) {
      if (value == null || value === '') {
        return '暂无';
      } else {
        return value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.step-item {
  .exp {
    color: #303133;
    .null {
      opacity: 0.3;
    }
    .exp-txt {
      width: 340px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .edit-exp {
    margin-left: 30px;
    opacity: 1;
    cursor: pointer;
    color: #2878ff;
  }
  &.null {
    opacity: 1;
    cursor: not-allowed;
    color: #c0c4cc;
  }
  &.overdue {
    color: #f04737;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .desc {
    line-height: 1.75;
    text-overflow: ellipsis; /*有些示例里需要定义该属性，实际可省略*/
    display: -webkit-box;
    -webkit-line-clamp: 2; /*规定超过两行的部分截断*/
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    white-space: initial;
    .gray {
      color: #c0c4cc;
    }
  }
  .desc__inner {
    max-height: 48px;
    overflow: hidden;
  }
  .autoHeight {
    height: auto;
    max-height: none;
    .desc {
      -webkit-line-clamp: 99999; /*规定超过两行的部分截断*/
    }
  }
}
.show-more {
  width: 405px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  justify-content: center;
  color: #2878ff;
  opacity: 0.3;
  i {
    font-size: 20px;
    position: relative;
    top: -1px;
    left: -4px;
  }
  &:hover {
    opacity: 1;
  }
}
</style>

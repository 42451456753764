import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const status = [
    {
      value: false,
      label: '否',
    },
    {
      value: true,
      label: '是',
    },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 150,
      type: 'input',
      fixed: 'left',
      value: '',
    },
    {
      label: '订单进度', // todolist
      prop: 'byteCurrentSteps',
      propDesc: 'currentStep',
      type: 'multiSelect',
      options: 'abnormal_type',
      minWidth: 142,
      value: [],
      component: defineComponent({
        template: `<div class="image-flex" @click="showLoading(row)">
        <span> {{row.currentStep}}</span>
         <span class="icon-progress"></span>
        </div>
        `,
        props: ['row'],
        setup() {
          const showLoading = (row) => {
            Instance.setupState.showLoading(row);
          };
          return { showLoading };
        },
      }),
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 157,
      type: 'input',
      value: '',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      minWidth: 99,
      type: 'input',
      value: '',
    },
    {
      label: '厂号',
      prop: 'plantNo',
      // width: '',
      minWidth: 110,
      type: 'input',
      value: '',
    },

    {
      label: '品名',
      prop: 'nameCn',
      propDesc: 'nameCnMerged',
      minWidth: 141,
      type: 'input',
      value: '',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 140,
      type: 'input',
      value: '',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 100,
      type: 'input',
      value: '',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      type: 'input',
      value: '',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 153,
      type: 'input',
      value: '',
    },
    {
      label: '代理',
      prop: 'agencyName',
      minWidth: 101,
      type: 'input',
      value: '',
    },
    {
      label: '口岸',
      prop: 'portName',
      minWidth: 99,
      type: 'input',
      value: '',
    },
    {
      label: '是否垫资',
      prop: 'isNeedJr',
      propDesc: 'isNeedJrDesc',
      type: 'signSelect',
      options: status,
      // width: '',
      minWidth: 130,
      value: '',
    },
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      // width: '',
      type: 'signSelect',
      options: status,
      value: '',
    },
    {
      label: '进度异常',
      prop: 'byteStepAbnormal',
      propDesc: 'abnormal',
      type: 'multiSelect',
      options: 'abnormal_type',
      minWidth: 130,
      value: [],
    },
    {
      label: '启运日',
      prop: 'shippingStartDate',
      filterProp: ['shippingStartStartDate', 'shippingStartEndDate'],
      type: 'time', // 筛选类型 time 日期
      minWidth: 111,
      value: [],
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',
      filterProp: ['shippingEndStartDate', 'shippingEndEndDate'],
      type: 'time', // 筛选类型 time 日期
      minWidth: 111,
      value: [],
    },
    {
      label: '柜号',
      prop: 'containerNo',
      minWidth: 123,
      type: 'input',
      value: '',
    },
    {
      label: '提单号',
      prop: 'ladingBillNo',
      type: 'input',
      minWidth: 138,
      value: '',
    },
    {
      label: '船名航次',
      prop: 'shippingInfo',
      minWidth: 144,
      type: 'input',
      value: '',
    },
    {
      label: '实际净重(kg)',
      prop: 'actualWeightSum',
      filterProp: ['actualWeightMinVal', 'actualWeightMaxVal'], // actualWeightSum
      propDesc: 'actualWeightSumDisplay',
      minWidth: 154,
      type: 'number',
      value: [],
    },
    {
      label: '实际货值',
      prop: 'actualAmountSum', // actualWeightSum
      propDesc: 'actualAmountSumDisplay',
      filterProp: ['actualAmountSumMinVal', 'actualAmountSumMaxVal'], // actualWeightSum
      minWidth: 130,
      type: 'number',
      value: [],
    },
    {
      type: 'time',
      label: '销售合同到期日',
      prop: 'businessContractEndDate',
      filterProp: ['businessContractStartDate', 'businessContractEndDate'],
      minWidth: 175,
      component: defineComponent({
        template: `
        <div :style="{color:row.color?row.color:'#303133'}">{{filterName(row.businessContractEndDateDesc)}}</div>
        `,
        props: ['row'],
      }),
      value: [],
    },
    {
      label: '提货状态', // todolist
      prop: 'pickStatus',
      propDesc: 'pickStatusDesc',
      minWidth: 130,
      type: 'multiSelect',
      options: 'pick_status',
      value: [],
    },
    {
      label: '客户是否结清', // todolist
      prop: 'settleStatus',
      propDesc: 'settleStatusDesc',
      minWidth: 160,

      type: 'signSelect',
      options: 'settle_progress_type_status',

      value: '',
    },
    {
      label: '开票状态', // todolist
      prop: 'invoiceStatus',
      propDesc: 'invoiceStatusDesc',
      minWidth: 130,

      type: 'signSelect',
      options: 'invoice_progress_type_status',

      value: '',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      minWidth: 128,
      type: 'multiSelect',
      options: 'order_status',
      value: [1, 4],
    },
    {
      label: '签约日期',
      prop: 'signDate',
      filterProp: ['signStartDate', 'signEndDate'],
      minWidth: 174,
      type: 'time',
      value: [],
    },
    {
      label: '完结日期',
      prop: 'finishDate',
      filterProp: ['finishStartDate', 'finishEndDate'],
      minWidth: 174,
      type: 'time',
      value: [],
    },
    {
      label: '单证跟单员',
      prop: 'userNameDoc',
      propDesc: 'userNameDocMerged',
      minWidth: 161,
      type: 'input',
      value: '',
    },
    {
      label: '资管跟单员',
      prop: 'userNameFund',
      propDesc: 'userNameFundMerged',
      minWidth: 161,

      type: 'input',

      value: '',
    },
    {
      label: '客户经理',
      prop: 'clientManager',
      propDesc: 'clientManager',
      minWidth: 128,

      type: 'input',

      value: '',
    },
    {
      label: '采购',
      prop: 'purchase',
      propDesc: 'purchase',
      minWidth: 101,
      type: 'input',
      value: '',
    },
    {
      label: '订单创建时间',
      prop: 'createdTime',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
      minWidth: 174,
      type: 'time',
      value: [],
    },
  ];
  const exportAllOptions: ErpTableOptions[] = [
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      minWidth: 128,
      value: [3],
      type: 'multiSelect',
      options: 'order_status',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 153,
      type: 'input',
      value: '',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 170,
      value: '',
      type: 'input',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      value: '',
      minWidth: 99,
      type: 'input',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '柜号',
      prop: 'containerNo',
      minWidth: 140,
      value: '',
      type: 'input',
    },
    {
      label: '提单号',
      prop: 'ladingBillNo',

      value: '',
      type: 'input',
    },
    {
      label: '是否垫资',
      prop: 'isNeedJr',
      propDesc: 'isNeedJrDesc',
      type: 'signSelect',
      options: status,
      value: '',
      minWidth: 130,
    },
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      value: '',
      type: 'signSelect',
      options: status,
    },
    {
      label: '启运日',
      prop: 'shippingStartDate',
      value: [],
      type: 'time',
      filterProp: ['shippingStartStartDate', 'shippingStartEndDate'],
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',

      value: [],
      type: 'time',
      filterProp: ['shippingEndStartDate', 'shippingEndEndDate'],
    },
    {
      label: '订单签约日期',
      prop: 'signDate',

      value: [],
      type: 'time',
      filterProp: ['signDateStartDate', 'signDateEndDate'],
    },
    {
      label: '付费方式',
      prop: 'payType',
      minWidth: 128,
      value: [],
      type: 'multiSelect',
      options: 'pay_type', // todo
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '口岸',
      prop: 'portName',

      value: '',
      minWidth: 99,
      type: 'input',
    },
  ];
  const PurchaseOrderOptions: ErpTableOptions[] = [
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      value: true,
      type: 'signSelect',
      options: status,
      isRequired: true,
      isDisabled: true,
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      minWidth: 128,
      value: [1, 3],
      type: 'multiSelect',
      options: 'order_status',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'contractPrincipalName',
      minWidth: 153,
      type: 'input',
      value: '',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 170,
      value: '',
      type: 'input',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',

      value: '',
      minWidth: 99,
      type: 'input',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '柜号',
      prop: 'containerNo',
      minWidth: 140,
      value: '',
      type: 'input',
    },
    {
      label: '提单号',
      prop: 'ladingBillNo',

      value: '',
      type: 'input',
    },
    {
      label: '是否垫资',
      prop: 'isNeedJr',
      propDesc: 'isNeedJrDesc',
      type: 'signSelect',
      options: status,
      value: '',
      minWidth: 130,
    },
    {
      label: '启运日',
      prop: 'shippingStartDate',

      value: [],
      type: 'time',
      filterProp: ['shippingStartStartDate', 'shippingStartEndDate'],
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',

      value: [],
      type: 'time',
      filterProp: ['shippingEndStartDate', 'shippingEndEndDate'],
    },
    {
      label: '订单签约日期',
      prop: 'signDate',

      value: [],
      type: 'time',
      filterProp: ['signDateStartDate', 'signDateEndDate'],
    },
    {
      label: '付费方式',
      prop: 'payType',
      minWidth: 128,
      value: [],
      type: 'multiSelect',
      options: 'pay_type', // todo
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '口岸',
      prop: 'portName',

      value: '',
      minWidth: 99,
      type: 'input',
    },
  ];

  return { columnList, exportAllOptions, PurchaseOrderOptions, tableRef };
};

//

<template>
  <div v-loading="loading" element-loading-text="正在导入，请勿进行其他操作">
    <div>
      <!-- 应收项目 -->
      <div>
        <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
          <div class="erp-form__title f16 no-leftborder">错误日志</div>
        </div>
        <div style="padding: 21px">
          <el-table :data="errorDataLocal" border class="egrid erp-table__list erp-view__table" highlight-current-row height="600">
            <el-table-column label="销售合同号" align="center" show-overflow-tooltip width="200px" fixed>
              <template #default="scope">
                <el-input v-if="scope.row.isEdit" v-model="scope.row.businessContractNo" />
                <div v-else :class="{ errorRow: hasErrorRow(scope.row, 1) }">
                  {{ filterName(scope.row.businessContractNo) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="物流信息" align="center" class-name="no-pd">
              <el-table-column label="启运日" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.shippingStartDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 2) }">
                    {{ filterName(scope.row.shippingStartDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="到港日" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.shippingEndDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 3) }">
                    {{ filterName(scope.row.shippingEndDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="起运港" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.portLoading" maxlength="50" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 4) }">
                    {{ filterName(scope.row.portLoading) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="投保日" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.insureTimeDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 5) }">
                    {{ filterName(scope.row.insureTimeDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="船公司" align="center" class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" v-model="scope.row.shipName" maxlength="50" value-key="shipName" clearable>
                    <el-option v-for="item in shipCompanyList" :key="item.id" :label="item.shipName" :value="item.shipName" />
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 6) }">
                    {{ scope.row.shipName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="船名航次" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.shippingInfo" maxlength="50" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 7) }">
                    {{ filterName(scope.row.shippingInfo) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="柜号" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.containerNo" maxlength="13" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 8) }">
                    {{ filterName(scope.row.containerNo) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提单号" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.ladingBillNo" maxlength="50" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 9) }">
                    {{ filterName(scope.row.ladingBillNo) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="生产日期" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.produceDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 10) }">
                    {{ filterName(scope.row.produceDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="物流描述" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.logisticsDesc" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 11) }">
                    {{ filterName(scope.row.logisticsDesc) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="副本文件" align="center" class-name="no-pd">
              <el-table-column label="日期" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.duplicateDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 12) }">
                    {{ filterName(scope.row.duplicateDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" placeholder="请选择状态" clearable v-model="scope.row.duplicateStatusDesc" class="full-input">
                    <el-option
                      v-for="item in filterOption.copy_progress_type_status"
                      :key="item.label"
                      :value="item.label"
                      :label="item.label"
                      :disabled="item.value == 0 || item.value == 1"
                    ></el-option>
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 13) }">{{ scope.row.duplicateStatusDesc }}</div>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.duplicateDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 14) }">
                    {{ filterName(scope.row.duplicateDescription) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="正本文件" align="center" class-name="no-pd">
              <el-table-column label="日期" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.originalDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 15) }">
                    {{ filterName(scope.row.originalDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" placeholder="请选择状态" clearable v-model="scope.row.originalStatusDesc" class="full-input">
                    <el-option
                      v-for="item in filterOption.origin_progress_type_status"
                      :key="item.label"
                      :value="item.label"
                      :label="item.label"
                      :disabled="item.value == 0 || item.value == 1"
                    ></el-option>
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 16) }">{{ scope.row.originalStatusDesc }}</div>
                </template>
              </el-table-column>
              <el-table-column label="放货方式" align="center" class-name="no-pd" width="200px">
                <template #default="scope">
                  <DictSelect
                    v-if="scope.row.isEdit"
                    v-model="scope.row.releaseTypeDesc"
                    name="order_qhdc_release_type"
                    keyValue="label"
                    keyValueOpt="label"
                    placeholder="请选择"
                  />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 17) }">{{ scope.row.releaseTypeDesc }}</div>
                </template>
              </el-table-column>
              <el-table-column label="快递公司" align="center" class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" v-model="scope.row.originalTrackingCompanyName" maxlength="50" value-key="companyName" clearable>
                    <el-option v-for="item in expCompanyOrgin" :key="item.companyCode" :label="item.companyName" :value="item.companyName" />
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 18) }">{{ scope.row.originalTrackingCompanyName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="正本快递号" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.originalTrackingNumber" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 19) }">
                    {{ filterName(scope.row.originalTrackingNumber) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.originalDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 20) }">
                    {{ filterName(scope.row.originalDescription) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="交报关行" align="center" class-name="no-pd">
              <el-table-column label="日期" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.connectDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 21) }">
                    {{ filterName(scope.row.connectDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" placeholder="请选择状态" clearable v-model="scope.row.connectStatusDesc" class="full-input">
                    <el-option
                      v-for="item in filterOption.clearance_progress_type_status"
                      :key="item.label"
                      :value="item.label"
                      :label="item.label"
                      :disabled="item.value == 0 || item.value == 1"
                    ></el-option>
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 22) }">{{ scope.row.connectStatusDesc }}</div>
                </template>
              </el-table-column>
              <el-table-column label="快递公司" align="center" class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-select v-if="scope.row.isEdit" v-model="scope.row.connectTrackingCompanyName" maxlength="50" value-key="companyName" clearable>
                    <el-option v-for="item in expCompany" :key="item.companyCode" :label="item.companyName" :value="item.companyName" />
                  </el-select>
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 23) }">{{ scope.row.connectTrackingCompanyName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="报关资料快递单号" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.connectTrackingNumber" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 24) }">
                    {{ filterName(scope.row.connectTrackingNumber) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.connectDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 25) }">
                    {{ filterName(scope.row.connectDescription) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="提箱中转" align="center" class-name="no-pd">
              <el-table-column label="提箱日期" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.packingContainerDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 26) }">
                    {{ filterName(scope.row.packingContainerDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="消杀日期" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.xsDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 27) }">
                    {{ filterName(scope.row.xsDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提箱描述" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.packingContainerDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 28) }">
                    {{ filterName(scope.row.packingContainerDescription) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="中转日期" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.transferDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 29) }">
                    {{ filterName(scope.row.transferDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="中转库" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.transferStorage" maxlength="20" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 30) }">
                    {{ filterName(scope.row.transferStorage) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="中转描述" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.transferDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 31) }">
                    {{ filterName(scope.row.transferDescription) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="还箱信息" align="center" class-name="no-pd">
              <el-table-column label="还箱日期" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <el-date-picker v-if="scope.row.isEdit" v-model="scope.row.returnDateDesc" type="date" value-format="YYYY-MM-DD" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 32) }">
                    {{ filterName(scope.row.returnDateDesc) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="免箱期" align="center" show-overflow-tooltip class-name="no-pd" label-class-name="required" width="200px">
                <template #default="scope">
                  <number-input v-if="scope.row.isEdit" v-model="scope.row.boxFreePeriod" :min="0" :max="1000" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 33) }">
                    {{ filterName(scope.row.boxFreePeriod) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="还箱地址" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.boxRetunAddress" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 34) }">
                    {{ filterName(scope.row.boxRetunAddress) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="描述" align="center" show-overflow-tooltip class-name="no-pd" width="200px">
                <template #default="scope">
                  <el-input v-if="scope.row.isEdit" v-model="scope.row.returnDescription" type="textarea" :rows="3" maxlength="100" />
                  <div v-else :class="{ errorRow: hasErrorRow(scope.row, 35) }">
                    {{ filterName(scope.row.returnDescription) }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="状态" align="center" class-name="no-pd" width="100px" fixed="right">
              <template #default="scope">
                <el-button v-if="!scope.row.isEdit" type="primary" size="small" @click="rowClick(scope.row)" round>编辑</el-button>
                <el-button v-else type="success" size="small" @click="closeEdit(scope.row)" round>预览</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 30px">
          <el-button size="small" type="primary" class="erp-primary__btn" style="margin-right: 50px" @click="confirmExport">确定</el-button>
          <div class="erp-default__btn" @click="cancel">取消</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NumberInput from '@/components/NumberInput/NumberInput.vue';
import XEUtils from 'xe-utils';
import { ref } from 'vue';
import { errorTip, successTip } from '@/utils/publicMethods';
import { httpPost } from '@/api';
import { store } from '@/store';

export default {
  components: {
    NumberInput,
  },
  props: {
    errorData: {
      type: [Object],
    },
  },
  emits: ['cancel', 'exportSuccess'],
  setup(props, ctx) {
    const filterOption = ref({});
    filterOption.value = {
      copy_progress_type_status: store.state.dict.options.copy_progress_type_status,
      origin_progress_type_status: store.state.dict.options.origin_progress_type_status,
      clearance_progress_type_status: store.state.dict.options.clearance_progress_type_status,
    };
    const errorDataLocal = ref([]);
    errorDataLocal.value = XEUtils.clone(props.errorData);
    const loading = ref(false);
    const customUrl = ref(process.env.VUE_APP_API_ROOT + '/malicrm/order/batchImportOrder');
    // 展示批量上传文件展示
    const expCompany = ref([]);
    const expCompanyOrgin = ref([]);
    const shipCompanyList = ref([]);

    // 取消
    const cancel = () => {
      ctx.emit('cancel', false, 1);
    };
    // 模板下载
    const downLoadTmp = async () => {
      // 下载模板地址
      this.$showFiles('批量导入表.xlsx', 'https://minio.malleegroup.com/template/批量导入导出功能模板.xlsx', false);
    };

    const shipCompany = async () => {
      let res = await httpPost('/malicrm/ship/shipList', {
        shipName: '',
        pageNum: 1,
        pageSize: 9999,
      });
      if (res.code === 200) {
        shipCompanyList.value = res.result.list;
        shipCompanyList.value.forEach((item) => {
          item.id = item.id * 1;
        });
      }
    };
    const expressCompany = async (source) => {
      let res = await httpPost('/malicrm/order/queryTrackingCompany', {
        companyName: '',
        source,
        type: 1,
      });
      if (res.code === 200) {
        if (source === 2) {
          expCompany.value = res.result;
        } else {
          expCompanyOrgin.value = res.result;
        }
      }
    };
    // 确认导入必填校验
    // const vaildForm = () => {
    //   return errorDataLocal.value.every(
    //     (item) =>
    //       item.shippingStartDate &&
    //       item.shippingEndDate &&
    //       item.portLoading &&
    //       item.company &&
    //       item.shippingInfo &&
    //       item.containerNo &&
    //       item.ladingBillNo &&
    //       item.produceDate &&
    //       item.duplicateStatus &&
    //       item.duplicateDescription &&
    //       item.originalStatus &&
    //       item.originalDescription &&
    //       item.connectStatus &&
    //       item.connectTrackingNumber &&
    //       item.connectDescription &&
    //       item.packingContainerDate &&
    //       item.xsDate &&
    //       item.transferStorage &&
    //       item.returnDate &&
    //       item.boxFreePeriod
    //   );
    // };

    // 确认导入
    const confirmExport = async () => {
      // if (!vaildForm()) {
      //   errorTip('请完成数据的必填校验');
      //   return;
      // }
      let res = await httpPost('/malicrm/order/sureBatchImportOrder', errorDataLocal.value);
      if (res.code === 200) {
        if (!res?.result?.length) {
          successTip('导入成功');
          ctx.emit('exportSuccess', true);
        } else {
          errorTip('导入文件有错误信息，请检查');
          errorDataLocal.value = res.result;
        }
      } else {
        errorTip(res.message);
      }
    };

    // 点击当前行 变成可编辑状态
    const rowClick = (row) => {
      // 排他思想  把除了当前行 其他行都不编辑
      errorDataLocal.value.forEach((item) => {
        item.isEdit = false;
      });
      row.isEdit = true;
    };
    // 关闭全部行的编辑状态
    const closeEdit = (row) => {
      row.isEdit = false;
    };
    const hasErrorRow = (row, index) => {
      if (row.errorNos.includes(1)) {
        return true;
      } else {
        return row.errorNos.includes(index);
      }
    };
    expressCompany(1);
    expressCompany(2);
    shipCompany();
    return {
      filterOption,
      expCompanyOrgin,
      errorDataLocal,
      expCompany,
      shipCompanyList,
      hasErrorRow,
      closeEdit,
      rowClick,
      downLoadTmp,
      loading,
      customUrl,
      cancel,
      confirmExport,
    };
  },
};
</script>
<style lang="scss" scoped>
.upload-tips {
  display: flex;
  align-items: center;
  white-space: pre;
  font-size: 14px;
  color: #9a9a9a;
  position: absolute;
  top: 192px !important;
  .record__link {
    color: #2878ff;
  }
  .under {
    text-decoration: underline;
  }
}
.import-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
.upload-box {
  position: relative;
}
.erp-form__title.req::after {
  content: '*';
  color: #f04838;
  margin-left: 4px;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
.header-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.header-tip {
  margin: 0 0 0 12px;
  color: #999999;
  font-size: 14px;
}
.template-link {
  color: #2878ff;
  cursor: pointer;
  text-decoration: underline;
}
.upload-tips {
  top: 242px;
}
.errorRow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fcecee;
  line-height: 40px;
}
::v-deep .el-date-editor .el-input__inner {
  padding-left: 30px !important;
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/list-del.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mali-full__inputcom[data-v-32f71ac7]{width:268px}.tip[data-v-32f71ac7]:after{content:\"注：导入时以选中的业务对象为依据\";font-size:14px;font-weight:400;color:#9da7b8;position:absolute;top:0;right:-240px}.table-title[data-v-32f71ac7]{margin:0 0 20px 20px}.addtemplate-dialog-footer[data-v-32f71ac7]{display:flex;width:100%;height:50px;justify-content:flex-end;align-items:center}.ml-8[data-v-32f71ac7]{margin-left:8px}.template-operation[data-v-32f71ac7]{width:18px;height:16px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.temp-check-box[data-v-32f71ac7],.temp-checked-box[data-v-32f71ac7]{width:18px;height:18px;border-radius:9px;border:1px solid #dbdee5;position:relative;left:8px;top:0}.temp-checked-box[data-v-32f71ac7]{background-color:#2878ff;display:flex;justify-content:center;align-items:center}.temp-checked-box .inner-box[data-v-32f71ac7]{width:6px;height:6px;background:#fff;border-radius:3px}.flex-center[data-v-32f71ac7]{display:flex;align-items:center;justify-content:center}.template-data-table[data-v-32f71ac7]{margin-bottom:40px}", ""]);
// Exports
module.exports = exports;
